// src/api.js 
// const API_BASE_URL = "http://127.0.0.1:8000/api"; 
// const STORAGE_BASE_URL = "http://127.0.0.1:8000/storage"; 

const API_BASE_URL = "https://mksapi.mksportal.com/api"; 
const STORAGE_BASE_URL = "https://mksapi.mksportal.com/storage";

export { STORAGE_BASE_URL };
export default API_BASE_URL;



